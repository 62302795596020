<template>
  <div v-if="box.id != null">
    <md-button
      class="md-raised md-icon-button mg-left-0 btn-back"
      @click="back"
    >
      <font-awesome-icon icon="arrow-left" />
    </md-button>

    <box-form :box="box" @onSubmit="onSubmit" :disabled="submitting" />

    <md-button
      class="md-raised md-accent md-icon-button mg-left-0 remove"
      @click="remove"
    >
      <font-awesome-icon icon="trash" />
    </md-button>
  </div>
  <div v-else>
    <md-empty-state
      md-rounded
      md-icon="reorder"
      :md-label="$t('first_select_a_box')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Box } from "../../common/api/api.service";
import {
  ALERT,
  BOX_RELOAD,
  BOX_SELECT_DETAILS,
  CONFIRM_SHOW,
} from "../../store/actions.types";
import BoxForm from "./BoxForm.vue";
export default {
  name: "BoxContentInfo",
  components: {
    BoxForm,
  },

  computed: {
    ...mapGetters({
      box: "getSelectedBox",
    }),
  },

  data() {
    return {
      submitting: false,
    };
  },

  methods: {
    onSubmit(box) {
      this.submitting = true;
      Box.put(box)
        .then(() => {
          this.$toast.success(this.$t("updated!"));
          this.$store.dispatch(BOX_RELOAD);
          this.$store.dispatch(BOX_SELECT_DETAILS, {});
          this.$emit("back");
        })
        .catch(() => {
          this.$store.dispatch(ALERT, { message: "error" });
        })
        .finally(() => {
          this.submitting = false;
        });
    },

    remove() {
      this.$store.dispatch(CONFIRM_SHOW, {
        title: "remove?",
        warning: true,
        confirm: this.$t("remove"),
        cancel: this.$t("cancel"),
        onConfirm: () => {
          Box.delete(this.box.id).then(() => {
            this.$emit("back");
            this.$store.dispatch(BOX_RELOAD);
            this.$store.dispatch(BOX_SELECT_DETAILS, {});
          });
        },
      });
    },

    back() {
      this.$emit("back");
    },
  },
};
</script>

<style scoped>
@media (min-width: 959px) {
  .btn-back {
    display: none;
  }
}
.btn-back {
  margin-bottom: 10px;
}
.remove {
  margin-top: 10px;
}
</style>
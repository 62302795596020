<template>
  <div class="box-list">
    <md-list>
      <md-list-item
        v-for="box in boxes.content"
        :key="box.id"
        @click="selectBox(box)"
        :class="{ selected: box.id === selected.id }"
      >
        <div class="md-list-item-text">
          <span>
            {{ box.reference }}
            <span v-if="box.description"> - {{ box.description }} </span>
          </span>
          <span>{{ formatDateTime(box.createdDate) }} </span>
        </div>
      </md-list-item>

      <md-list-item
        v-if="!boxes.last"
        class="load-more"
        v-observe-visibility="visibilityChange"
        @click="loadMore"
      >
        {{ $t("load_more") }}...
      </md-list-item>
    </md-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateMixin } from "../../common/mixins/DateMixin";
import { BOX_LOAD_MORE, BOX_SELECT_DETAILS } from "../../store/actions.types";
export default {
  name: "BoxList",
  mixins: [DateMixin],
  computed: {
    ...mapGetters({
      boxes: "getBoxes",
      selected: "getSelectedBox",
    }),
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    selectBox(box) {
      this.$emit("boxSelected");
      this.$store.dispatch(BOX_SELECT_DETAILS, box);
    },
    visibilityChange(isVisible) {
      if (isVisible) {
        this.loadMore();
      }
    },
    loadMore() {
      if (!this.loading) {
        this.loading = true;
        this.$store.dispatch(BOX_LOAD_MORE).finally(() => {
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style scoped>
.selected {
  background: #ebebeb;
}
.load-more .md-list-item-container >>> .md-list-item-content {
  justify-content: center;
}
.md-list-item {
  margin: 2px 0;
}
</style>
<template>
  <div v-if="boxes && boxes.totalElements > 0">
    <div class="md-layout md-alignment-top-space-between ">
      <box-content-list
        class="
          md-layout-item md-size-20 md-medium-size-30 md-small-size-100
          content-info 
        "
        :class="{ 'md-small-hide': hideList }"
        :style="`height: ${height - 15}px`"
        @boxSelected="boxSelected"
      />
      <box-content-info
        class="
          md-layout-item md-size-75 md-medium-size-65 md-small-size-100
          content-info
        "
        :class="{ 'md-small-hide': !hideList }"
        :style="`height: ${height - 15}px`"
        @back="back"
      />
    </div>
  </div>
  <div v-else>
    <md-empty-state md-rounded md-icon="add" :md-label="$t('no_boxes_created')">
      <md-button class="md-raised md-primary" :to="{ name: 'BoxCreate' }">
        {{ $t("create") }}
      </md-button>
    </md-empty-state>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BoxContentInfo from "./BoxContentInfo.vue";
import BoxContentList from "./BoxContentList.vue";
export default {
  name: "BoxContent",
  components: {
    BoxContentList,
    BoxContentInfo,
  },

  props: {
    height: Number,
  },

  data() {
    return {
      hideList: false,
    };
  },

  computed: {
    ...mapGetters({
      boxes: "getBoxes",
    }),
  },

  methods: {
    boxSelected() {
      this.hideList = true;
    },
    back() {
      this.hideList = false;
    },
  },
};
</script>

<style scoped>
.content-info {
  margin-top: 15px;
  height: 100px;
  overflow-y: auto;
  padding-right: 15px;
}
</style>
<template>
  <div class="md-layout">
    <div
      class="
        md-layout-item
        md-size-70
        md-medium-size-100
        md-layout
        md-alignment-top-space-between
      "
    >
      <md-field
        class="md-layout-item md-size-100"
        :class="{ 'md-invalid': $v.form.reference.$invalid && !readOnly }"
      >
        <label> {{ $t("reference") }} </label>
        <md-input v-model="form.reference" :disabled="readOnly" />
      </md-field>

      <md-field class="md-layout-item md-size-100">
        <label> {{ $t("description") }} </label>
        <md-textarea v-model="form.description" :disabled="readOnly" />
      </md-field>

      <md-field class="md-layout-item md-size-45">
        <label> {{ $t("material") }} </label>
        <md-input v-model="form.material" :disabled="readOnly" />
      </md-field>

      <md-field class="md-layout-item md-size-45">
        <label> {{ $t("thickness") }} </label>
        <md-input
          type="number"
          min="0"
          v-model.number="form.thickness"
          :disabled="readOnly"
        />
      </md-field>

      <md-field class="md-layout-item md-size-45">
        <label> {{ $t("weight") }} (g)</label>
        <md-input
          type="number"
          min="0"
          v-model.number="form.weight"
          :disabled="readOnly"
        />
      </md-field>

      <md-field
        class="md-layout-item md-size-45"
        :class="{ 'md-invalid': $v.form.height.$invalid && !readOnly }"
      >
        <label> {{ $t("height") }} (mm)</label>
        <md-input
          type="number"
          min="0"
          v-model.number="form.height"
          :disabled="readOnly"
        />
      </md-field>

      <md-field
        class="md-layout-item md-size-45"
        :class="{ 'md-invalid': $v.form.width.$invalid && !readOnly }"
      >
        <label> {{ $t("width") }} (mm)</label>
        <md-input
          type="number"
          min="0"
          v-model.number="form.width"
          :disabled="readOnly"
        />
      </md-field>

      <md-field
        class="md-layout-item md-size-45"
        :class="{ 'md-invalid': $v.form.length.$invalid && !readOnly }"
      >
        <label> {{ $t("length") }} (mm)</label>
        <md-input
          type="number"
          min="0"
          v-model.number="form.length"
          :disabled="readOnly"
        />
      </md-field>

      <md-field class="md-layout-item md-size-45">
        <label> {{ $t("inner_volume") }} </label>
        <md-input
          type="number"
          min="0"
          v-model.number="form.innerVolume"
          :disabled="readOnly"
        />
      </md-field>

      <md-field class="md-layout-item md-size-45">
        <label> {{ $t("greater_diagonal") }} </label>
        <md-input
          type="number"
          min="0"
          v-model.number="form.greaterDiagonal"
          :disabled="readOnly"
        />
      </md-field>

      <md-field class="md-layout-item md-size-45">
        <label> {{ $t("main_plane") }} </label>
        <md-input v-model.number="form.mainPlane" :disabled="readOnly" />
      </md-field>

      <div class="md-layout-item md-size-100" v-if="!readOnly">
        <md-button
          class="md-raised mg-left-0"
          @click="onSubmit"
          :disabled="disabled"
        >
          {{ $t(submitTxt) }}
        </md-button>
      </div>

      <!-- <pre>{{ $v }}</pre> -->
    </div>
  </div>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
import { ALERT } from "../../store/actions.types";
export default {
  name: "BoxForm",
  props: {
    box: {
      type: Object,
      default: function () {
        return {};
      },
    },
    submitTxt: {
      type: String,
      default: "submit",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {},
    };
  },

  validations: {
    form: {
      reference: {
        required,
      },
      height: {
        required,
        minValue: minValue(0),
      },
      width: {
        required,
        minValue: minValue(0),
      },
      length: {
        required,
        minValue: minValue(0),
      },
    },
  },

  watch: {
    box: function () {
      this.initData();
    },
  },

  beforeMount() {
    this.initData();
  },

  methods: {
    initData() {
      this.form = JSON.parse(JSON.stringify(this.box));
    },

    onSubmit() {
      if (this.$v.$invalid) {
        this.$store.dispatch(ALERT, { message: "verify_data" });
      } else {
        this.$emit("onSubmit", this.form);
      }
    },
  },
};
</script>

<style scoped>
input[type="number"] {
  width: 100%;
}
</style>
<template>
  <div>
    <!-- TOP BTNS -->
    <md-button class="md-raised md-icon-button mg-left-0" @click="reloadData">
      <font-awesome-icon icon="redo" />
      <md-tooltip md-direction="bottom"> {{ $t("reload") }} </md-tooltip>
    </md-button>

    <md-button
      class="md-raised md-icon-button mg-left-0"
      @click="onSearchClick"
    >
      <font-awesome-icon icon="search" />
      <md-tooltip md-direction="bottom"> {{ $t("search") }} </md-tooltip>
    </md-button>

    <md-button
      class="md-raised md-icon-button mg-left-0"
      :to="{ name: 'BoxCreate' }"
    >
      <font-awesome-icon icon="plus" />
      <md-tooltip md-direction="bottom"> {{ $t("box_create") }} </md-tooltip>
    </md-button>

    <md-button
      class="md-raised md-icon-button mg-left-0"
      :to="{ name: 'BoxImport' }"
    >
      <font-awesome-icon icon="upload" />
      <md-tooltip md-direction="bottom"> {{ $t("box_import") }} </md-tooltip>
    </md-button>

    <box-search ref="box-search" v-show="searchVisible" />

    <md-progress-bar v-if="loading" md-mode="indeterminate" />
    <box-content v-else ref="content" :style="contentHeight" :height="height" />
  </div>
</template>

<script>
import BoxContent from "../../components/box/BoxContent.vue";
import {
  BOX_LOAD,
  BOX_RELOAD,
  BOX_UNLOAD,
  TOOLBAR_TITLE,
} from "../../store/actions.types";
import BoxSearch from "../../components/box/BoxSearch.vue";

export default {
  name: "Box",
  components: {
    BoxContent,
    BoxSearch,
  },

  data() {
    return {
      loading: true,
      contentHeight: "",
      height: 100,
      searchVisible: false,
    };
  },

  mounted() {
    this.$store.dispatch(TOOLBAR_TITLE, "box");
    this.loadData();
    window.addEventListener("resize", this.calculateHeight);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.calculateHeight);
    this.$store.dispatch(BOX_UNLOAD);
  },

  methods: {
    async loadData() {
      await this.$store.dispatch(BOX_LOAD);
      this.loading = false;
      this.$nextTick(() => {
        this.calculateHeight();
      });
    },

    reloadData() {
      this.$store.dispatch(BOX_RELOAD);
    },

    calculateHeight() {
      let min = this.$refs.content.$el.getBoundingClientRect().top;
      let max = window.innerHeight;
      let size = max - min - 17;
      this.height = size;
      this.contentHeight = `height: ${size}px;`;
    },

    onSearchClick() {
      this.searchVisible = !this.searchVisible;
      this.$nextTick(() => {
        this.calculateHeight();
        if (this.searchVisible) {
          this.$refs["box-search"].$refs["box-search-input"].$el.focus();
        }
      });
    },
  },
};
</script>
